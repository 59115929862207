/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import React from "react";
import chroma from "chroma-js";

import { createDateTimeRange } from "../../../pages/capacity-building/__id/view/view";
import EventBox from "../../layout/EventBox/EventBox";
import Link from "../../theme/3.0/Link";
import { DateService } from "../../../service/DateService";
import { faCalendarPlus } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import ICalendarLink from "react-icalendar-link";
import MarkdownRenderer from "../../general/MarkdownRenderer";
import { CategoryTags } from "./Tags";

type Props = {
    event;
    featured?: Boolean;
    simple?: Boolean;
    showIntro?: Boolean;
    calendar?: Boolean;
    video?: Boolean;
    className?: string;
};

class CapacityBuildingEventBox extends React.Component<Props> {
    render() {
        const {
            event,
            featured = false,
            simple = false,
            showIntro = false,
            calendar = false,
            video = false
        } = this.props;
        // console.log({ event });
        let { className } = this.props;
        const date = createDateTimeRange(event.date, event.endDate);
        const inFuture = DateService.inFuture(new Date(event.date));
        const link = video
            ? {
                  routeKey: "news-and-events-view-news",
                  query: { id: event.id }
              }
            : event.type === "Event"
            ? {
                  routeKey: "capacity-building-view",
                  //   routeKey: "news-and-events-view-events",
                  query: { id: event.id }
              }
            : {
                  routeKey: "capacity-building-view",
                  query: { id: event.id }
              };

        const image = event.bannerImage
            ? event.bannerImage?.fields?.file?.url
            : event.image;
        // className = className + " d-flex flex-column align-items-between";
        return (
            <>
                <style jsx>
                    {`
                        .date-location {
                            display: flex;
                            flex-direction: row;
                        }
                        .date {
                            color: #999;
                            font-size: 0.725rem;
                            display: flex;
                            flex-direction: column;
                        }
                        .day {
                            font-size: 2.333333rem;
                        }
                        .location {
                            font-size: 0.725rem;
                            padding-left: 1rem;
                        }
                    `}
                </style>
                <EventBox
                    topContent={
                        <div style={{ position: "relative" }}>
                            {calendar && (
                                <span className="date-location">
                                    <span className="date">
                                        <span className="month">
                                            {DateService.format(
                                                event.date,
                                                DateService.FORMAT_MMM
                                            )}{" "}
                                            <span className="year">
                                                {DateService.format(
                                                    event.date,
                                                    DateService.FORMAT_YYYY
                                                )}
                                            </span>
                                        </span>
                                        <span className="day">
                                            {DateService.format(
                                                event.date,
                                                DateService.FORMAT_DD
                                            )}
                                        </span>
                                    </span>
                                    <span className="location">
                                        {event.location}
                                    </span>
                                </span>
                            )}
                            {/* @ts-ignore */}
                            <CategoryTags item={event} />
                            {calendar && inFuture && (
                                // @ts-ignore
                                <ICalendarLink
                                    event={{
                                        title: event.name,
                                        startTime: event.date,
                                        endTime: event.endDate,
                                        description: event.register_link
                                            ? `Register here: ${event.register_link}`
                                            : ""
                                    }}
                                    filename={event.name
                                        .replace(/\s/g, "-")
                                        .toLowerCase()}
                                >
                                    <FontAwesomeIcon
                                        icon={faCalendarPlus as IconProp}
                                    />{" "}
                                    Add to Calendar
                                </ICalendarLink>
                            )}
                        </div>
                    }
                    middleContent={
                        <div className={className}>{event.name}</div>
                    }
                    bottomContent={
                        <div className={className}>
                            <>
                                <span className="date">{date}</span>
                                {inFuture && (
                                    <div className="pt-2">
                                        {!showIntro && event.register_link && (
                                            <>
                                                <Link
                                                    target={"_blank"}
                                                    url={event.register_link}
                                                    label={"Register"}
                                                    size={"md"}
                                                    colour={"success"}
                                                    isButton
                                                />
                                            </>
                                        )}
                                    </div>
                                )}
                            </>
                            {showIntro && (
                                <p
                                    style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        display: "-webkit-box",
                                        lineClamp: 2,
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: "vertical"
                                    }}
                                >
                                    <MarkdownRenderer
                                        content={event.description}
                                    />
                                </p>
                            )}
                        </div>
                    }
                    image={!calendar ? image : null}
                    imageTitle={event.name}
                    imagePosition={calendar ? "none" : "bottom-cover"}
                    link={link}
                    columnSizes={
                        featured
                            ? { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }
                            : {}
                    }
                    featured={featured}
                    className={calendar ? "cardboard" : ""}
                />
            </>
        );
    }
}
export default CapacityBuildingEventBox;
