import React from "react";
import Authenticated from "../../components/Authenticated";
import BasicPage from "../../components/layout/BasicPage";
import theme from "../../components/theme/3.0/theme";
import { PAGE_EVENTS, PAGE_NEWS } from "../../constants";
import { GET_TRAINING_EVENTS } from "../../graphql/query/TrainingQuery";
import ContentfulService from "../../service/ContentfulService";
import { DateService } from "../../service/DateService";
import { authenticateUser } from "../../utils/AuthenticationUtility";
import { trainingSteps } from "../../utils/TourSteps";
import { withApollo } from "../../utils/WithApollo";
import { getCookiesFromCtx } from "../../utils/CommonUtils";
import Cookies from "js-cookie";
import CapacityBuildingOverview from "../../components/page/capacity-building/CapacityBuildingOverview";

type Props = {
    videos;
    pastEvents;
    futureEvents;
    trainingPage;
    eventsAndTotal;
    currentRouteKey: string;
    courses;
};
class CapacityBuilding extends React.Component<Props> {
    constructor(props) {
        super(props);
    }
    static async getInitialProps(ctx) {
        await authenticateUser(ctx.apolloClient, ctx, undefined);

        const { futureEvents, pastEvents } = await ctx.apolloClient
            .query({
                query: GET_TRAINING_EVENTS,
                variables: {
                    limit: 1000,
                    filters: {
                        "fields.isPrivate[ne]": true
                    }
                }
            })
            .then(({ data }) => {
                const events = data.events ? data.events : [];

                const futureEvents = events
                    .filter((article) =>
                        DateService.compareDates(
                            DateService.parse(article.startDate),
                            ">=",
                            new Date()
                        )
                    )
                    .sort((a, b) =>
                        DateService.compareDates(
                            DateService.parse(a.startDate),
                            "<",
                            DateService.parse(b.startDate)
                        )
                    );

                const pastEvents = events
                    .filter((article) =>
                        DateService.compareDates(
                            DateService.parse(article.startDate),
                            "<",
                            new Date()
                        )
                    )
                    .slice(0, 4);

                return {
                    futureEvents,
                    pastEvents
                };
            })
            .catch((e) => {
                console.error(e);
                return {
                    futureEvents: [],
                    pastEvents: []
                };
            });

        const cookiesJSON = getCookiesFromCtx(ctx);
        const contentfulService = /* new */ ContentfulService(cookiesJSON);

        // newsArticlesAndTotal = await contentfulService
        //     .getArticles(PAGE_NEWS, 12)
        //     .then(({ articles, total }) => ({ articles, total }));
        let eventsAndTotal = {};
        eventsAndTotal = await contentfulService
            .getArticles(PAGE_EVENTS, 24, 0, {
                "fields.convergenceInvolvement": "Yes",
                // "fields.isPrivate[ne]": true,
                "metadata.tags[exists]": true,
                order: "-fields.date"
            })
            .then(({ articles, total }) => ({ articles, total }));

        const trainingPage = await contentfulService.getSinglePage("Training");
        const courses = await contentfulService.getArticles("course");
        const videos = await contentfulService
            .getArticles(PAGE_NEWS, 3, 0, {
                "fields.type": "Videos",
                order: "-fields.date"
            })
            .then(({ articles }) => {
                return articles;
            })
            .catch((e) => {
                console.error(e);
                return [];
            });

        const cookies = cookiesJSON ? cookiesJSON : Cookies.get();
        return {
            videos,
            futureEvents,
            pastEvents,
            eventsAndTotal,
            trainingPage,
            currentRouteKey: ctx.query.routeKey,
            cookies,
            courses: courses.articles
        };
    }

    render() {
        const {
            videos,
            pastEvents,
            futureEvents,
            trainingPage,
            eventsAndTotal,
            courses,
            currentRouteKey
        } = this.props;

        const { articles, total } = eventsAndTotal;
        return (
            <Authenticated passNullUser enableUserChecks>
                {(user) => {
                    const steps = trainingSteps(user);
                    const featuredCourse = user
                        ? courses.filter(
                              (course) =>
                                  course.access === "Members" && course.featured
                          )[0]
                        : courses.filter(
                              (course) =>
                                  course.access === "Public" && course.featured
                          )[0] ||
                          courses.filter(
                              (course) =>
                                  course.access === "Members" && course.featured
                          )[0];
                    const memberFeaturedCourse =
                        featuredCourse?.access === "Members";
                    const featuredCourseUrl = user
                        ? `https://learning.convergence.finance/?rest_route=/simple-jwt-login/v1/autologin&redirectUrl=https://learning.convergence.finance${featuredCourse?.learndash_link}`
                        : memberFeaturedCourse
                        ? "#"
                        : `https://learning.convergence.finance${featuredCourse?.learndash_link}`;
                    return (
                        <React.Fragment>
                            <style jsx global>
                                {`
                                    :target {
                                        scroll-margin-top: 99px;
                                    }
                                    @media (min-width: ${theme.breakpoint.md}) {
                                        :target {
                                            scroll-margin-top: 84px;
                                        }
                                    }
                                `}
                            </style>
                            <BasicPage
                                currentRouteKey={this.props.currentRouteKey}
                                pageTitle={
                                    this.props.currentRouteKey ===
                                    "capacity-building"
                                        ? "Capacity Building"
                                        : this.props.currentRouteKey ===
                                          "event-calendar"
                                        ? "Event Calendar"
                                        : "Past Event Library"
                                }
                                description={
                                    this.props.currentRouteKey ===
                                    "capacity-building"
                                        ? "Convergence offers members a range of blended finance learning and capacity building programs. These programs help members: i) understand the potential of blended finance to support in achieving the SDGs and surface credible investment opportunities in developing countries, ii) build the case internally for blended finance based on the financial and development impact of blended finance to-date, iii) develop a blended finance strategy and approach, and iv) develop the capacity to participate effectively in blended finance transactions, from due diligence to monitoring."
                                        : this.props.currentRouteKey ===
                                          "event-calendar"
                                        ? "Stay up to date with the latest blended finance events."
                                        : "View our library of past blended finance events."
                                }
                            >
                                {/* @ts-ignore */}
                                <CapacityBuildingOverview
                                    events={articles}
                                    videos={videos}
                                    courses={courses}
                                    user={user}
                                    // grantArticles={articles}
                                    // fundingWindows={fundingWindows}
                                    // designFundingPage={designFundingPage?.config}
                                    // faqs={faqs}
                                    currentRouteKey={currentRouteKey}
                                />
                            </BasicPage>
                        </React.Fragment>
                    );
                }}
            </Authenticated>
        );
    }
}

export default withApollo(CapacityBuilding);
