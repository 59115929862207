/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import PropTypes from "prop-types";
import React from "react";
import Spinner from "./Spinner";

type Props = {
    color;
    height;
    width;
    children?;
};
const Loader = ({ color, height, width, children }: Props) => {
    if (typeof color === "undefined") {
        color = "#000000";
    }

    if (typeof height === "undefined") {
        height = "20px";
    }

    if (typeof width === "undefined") {
        width = "20px";
    }

    return (
        <React.Fragment>
            <style jsx>
                {`
                    .loader {
                        position: relative;
                        display: inline-block;

                        padding: 1rem;
                        border: 1px solid #e8e8e8;
                    }
                `}
            </style>
            <div className="loader">
                <Spinner style={{ marginRight: "10px" }} />
                {children ? children : "Loading"}
            </div>
        </React.Fragment>
    );
};

Loader.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string
};

export default Loader;
