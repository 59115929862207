/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import PropTypes from "prop-types";
import React, { ReactNode } from "react";

/**
 * Sticky
 */
type Props = {
    containerStyle;
    children: (isSticky?) => ReactNode;
    className?: string;
};
type State = {
    style;
    isSticky;
};
class Sticky extends React.Component<Props, State> {
    container: any;

    static propTypes = {
        children: PropTypes.func.isRequired,
        containerStyle: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            isSticky: false,
            style: { ...this.props.containerStyle }
        };
        this.container = null;
    }

    render() {
        const { children, className } = this.props;
        const { style, isSticky } = this.state;

        return (
            <React.Fragment>
                <style jsx>
                    {`
                        .make-sticky {
                            position: sticky;
                            top: 0;
                            background-color: white;
                            z-index: 1;
                        }
                    `}
                </style>
                <div
                    ref={(container) => (this.container = container)}
                    style={style}
                    className={
                        "make-sticky" + `${className ? " " + className : ""}`
                    }
                >
                    {children(isSticky)}
                </div>
            </React.Fragment>
        );
    }
}

export default Sticky;
