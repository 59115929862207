/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import PropTypes from "prop-types";
import React, { useState } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import { BRAND_COLOURS } from "../../../constants";
import ArticleBox from "../../layout/ArticleBox/ArticleBox";
import { ScrollingNavItem } from "../../layout/Menu/ScrollingNavItem";
import Loader from "../../layout/Loader";
import ScrollSpy from "../../layout/ScrollSpy";
import Sticky from "../../layout/Sticky";
import { Card, CardBody } from "../../theme/3.0/Card";
import Col from "../../theme/3.0/Grid/Col";
import Row from "../../theme/3.0/Grid/Row";
import Link from "../../theme/3.0/Link";
import theme from "../../theme/3.0/theme";
import QuestionAnswerPair from "../frequently-asked-questions/QuestionAnswerPair";
// import DesignFundingPortfolioStats from "./DesignFundingPortfolioStats";
import BaseLink from "../../BaseLink";
// import GrantPortfolio from "./GrantPortfolio";
import { DateService } from "../../../service/DateService";
import CapacityBuildingArticleBox from "./CapacityBuildingEventBox";
import CapacityBuildingEventBox from "./CapacityBuildingEventBox";
import ContentfulEventsListing from "./CapacityBuildingLibrary";
import ContentfulEventsCalendar from "./CapacityBuildingCalendar";
import { Carousel } from "../../theme/3.0/Carousel";

import { UncontrolledCarousel } from "reactstrap";
import { faLock } from "@fortawesome/fontawesome-free-solid";
import CourseArticleBox from "../courses/CourseArticleBox";

const items = [
    {
        src: "/static/social/rooftop.jpg",
        altText: "Slide 1"
        // caption: "Slide 1"
        // header: "Slide 1 Header"
    },
    {
        src: "/static/social/ny_mb_2023.jpg",
        altText: "Slide 2"
        // caption: "Slide 2"
        // header: "Slide 2 Header"
    },
    {
        src: "/static/social/126A4465.JPG",
        altText: "Slide 3"
        // caption: "Slide 3"
        // header: "Slide 3 Header"
    }
];

const SocialCarousel = () => (
    <UncontrolledCarousel
        items={items}
        className="d-flex w-100 slider"
        // style={{ height: 300 }}
        controls={false}
    />
);

const tabLink = (article, tabKey, tabLabel, currentPage) => {
    const active = tabKey === currentPage;
    return (
        null || (
            <BaseLink routeKey={tabKey}>
                {({ url }) => (
                    <NavLink
                        href={url}
                        active={active}
                        style={
                            active
                                ? {
                                      borderBottomColor:
                                          BRAND_COLOURS.secondary,
                                      borderBottomWidth: 3,
                                      borderLeft: 0,
                                      borderRight: 0,
                                      borderTop: 0
                                  }
                                : {
                                      //   borderLeft: 0,
                                      //   borderRight: 0,
                                      //   borderTop: 0
                                  }
                        }
                    >
                        {tabLabel}
                    </NavLink>
                )}
            </BaseLink>
        )
    );
};

const navLinks = [
    {
        hash: "learning-programs",
        label: "Learning Programs",
        active: true
    },
    {
        hash: "inside-the-deal",
        label: "Inside The Deal",
        active: false
    },
    {
        hash: "deal-connect",
        label: "Deal Connect",
        active: false
    },
    {
        hash: "thematic-webinars",
        label: "Thematic Webinars",
        active: false
    },
    {
        hash: "online-learning",
        label: "Online Learning",
        active: false
    },
    {
        hash: "bespoke-workshops",
        label: "Customized Workshops",
        active: false
    },
    {
        hash: "academic-partnerships",
        label: "Academic Partnerships",
        active: false
    },
    {
        hash: "networking-events",
        label: "Networking Events",
        active: false
    }
    // {
    //     hash: "library",
    //     label: "Learning Library",
    //     active: false
    // }
];

const portfolioNavLinks = [
    {
        hash: "portfolio-stats",
        label: "Portfolio Summary",
        active: true
    },
    {
        hash: "grant-portfolio",
        label: "Grants",
        active: false
    }
];

const logos = [
    { url: "/static/external-logos/sos-logo.ng.png", alt: "Alt text" },
    { url: "/static/external-logos/undp-logo-blue.svg", alt: "Alt text" },
    { url: "/static/external-logos/sig-rgb-horz-usa.svg", alt: "Alt text" }
];

const SideMenu = ({
    currentRouteKey,
    filteredNavLinks,
    portfolioNavLinks,
    ScrollingNavItem
}) => {
    const links =
        currentRouteKey === "capacity-building"
            ? filteredNavLinks
            : portfolioNavLinks;

    return (
        <Nav className="table-of-contents">
            <ScrollSpy navLinks={links} render={ScrollingNavItem} />
        </Nav>
    );
};

const FirstEvent = ({ event }) => {
    if (!event?.id) return null;
    return (
        <CapacityBuildingEventBox
            key={event.id}
            event={event}
            featured
            className="text-center"
        />
    );
};

const CapacitySection = ({
    heading,
    id,
    description,
    events,
    reverse,
    colour,
    bgColour,
    tag
}: {
    heading: string;
    id: string;
    description: any;
    events: any;
    reverse?: boolean;
    colour?: string;
    bgColour?: string;
    tag?: string;
}) => {
    const { upcomingEvents, recentEvents } = events.reduce(
        (result, event) => {
            const inFuture = DateService.inFuture(new Date(event.date));
            if (inFuture) {
                return {
                    upcomingEvents: [event, ...result.upcomingEvents],
                    recentEvents: [...result.recentEvents]
                };
            }
            return {
                recentEvents: [...result.recentEvents, event],
                upcomingEvents: [...result.upcomingEvents]
            };
        },
        { upcomingEvents: [], recentEvents: [] }
    );
    // get the featured event and remove it from upcoming/recent
    const featuredEvent = upcomingEvents.length
        ? upcomingEvents.shift()
        : recentEvents.shift();
    return (
        <section
            className="p-3 mb-5"
            id={`${id}`}
            style={{
                backgroundColor: `${bgColour || BRAND_COLOURS["light"]}`,
                color: `${colour || BRAND_COLOURS["dark"]}`
            }}
        >
            <div className="fluid-container pb-4 pl-3">
                <div className="row mr-0">
                    <div className={`col-md-6 p-4 order-md-${reverse ? 2 : 1}`}>
                        <h3 className="mb-4 tw-text-2xl">{heading}</h3>
                        <p>{description}</p>
                    </div>
                    <div className={`col-md-6 p-4 order-md-${reverse ? 1 : 2}`}>
                        <div className={"row"}>
                            <FirstEvent event={featuredEvent} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h4 className="mt-2 mb-4" id={`${id}-examples`}>
                            {upcomingEvents.length
                                ? "Upcoming Events"
                                : "Recent Examples"}
                        </h4>
                        <div className="d-flex flex-wrap">
                            {(upcomingEvents.length
                                ? upcomingEvents
                                : recentEvents
                            ).map((event) => (
                                <CapacityBuildingEventBox
                                    key={event.id}
                                    event={event}
                                    className="text-center"
                                />
                            ))}
                        </div>
                        <div className="d-flex justify-content-end">
                            <Link
                                target={""}
                                routeKey={"event-calendar-library"}
                                query={{ tag }}
                                label={"See Recent"}
                                size={"md"}
                                colour={"secondary"}
                                isButton
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const CapacityBuildingOverview = ({
    events,
    videos,
    fundingWindows,
    designFundingPage,
    faqs,
    courses,
    user,
    currentRouteKey
}) => {
    const [selectedWindow, setSelectedWindow] = useState();

    const onChangeWindow = (event) => setSelectedWindow(event.target.value);
    const upcomingWindows = fundingWindows?.filter(
        (window) => window.status === "Upcoming"
    );
    const inProgressWindows = fundingWindows?.filter(
        (window) => window.status === "In Progress"
    );
    const filteredNavLinks = navLinks.filter((link) => {
        if (
            upcomingWindows?.length === 0 &&
            link.hash === "upcoming-funding-windows"
        )
            return false;
        if (
            inProgressWindows?.length === 0 &&
            link.hash === "in-progress-funding-windows"
        )
            return false;
        return true;
    });
    const funders = fundingWindows?.reduce((pw, cw) => {
        if (cw.funders) {
            return [
                ...pw,
                ...cw.funders.filter(
                    (funder) =>
                        pw.findIndex((f) => f.sys.id === funder.sys.id) === -1
                )
            ];
        }
        return pw;
    }, []);

    const eventFilter = (events, filterTag) => {
        return events.filter((event) => {
            return event?.metadata?.tags?.find(
                (tag) => tag?.sys?.id === filterTag
            );
        });
    };
    const learningPrograms = eventFilter(events, "categoryLearningPrograms");
    const insideTheDeal = eventFilter(events, "categoryInsideTheDeal");
    const dealConnect = eventFilter(events, "categoryDealConnect");
    const thematicWebinars = eventFilter(events, "categoryThematicWebinars");
    // const bespokeWorkshops = eventFilter(events, "categoryBespokeWorkshops");
    // const onlineLearning = eventFilter(events, "categoryOnlineLearning");
    // const networkingEvents = eventFilter(events, "categoryNetworkingEvents");

    return (
        <React.Fragment>
            <style jsx global>
                {`
                    // .nav-tabs-gray {
                    //     border-bottom: "none !important";
                    // }
                    h4 {
                        font-size: 1.25rem;
                    }
                    .scrollNavItem * a.nav-link {
                        color: #555;
                        font-size: 0.8rem;
                    }
                    a.nav-link.active {
                        color: #29a4da;
                        font-weight: bold;
                    }
                    .title {
                        display: inline-block;
                        margin: 0 0 1rem 0;
                        padding: 10px;
                        background-color: rgba(0, 0, 0, 0.5);
                        -webkit-font-smoothing: antialiased;
                        color: ${BRAND_COLOURS.white};
                        text-transform: initial;
                    }
                    .title:first-line {
                        text-transform: initial;
                    }
                    .number {
                        font-size: 5em;
                        text-align: center;
                        color: #14588c;
                    }
                    .chart-title {
                        text-align: center;
                        text-transform: uppercase;
                        margin-bottom: 20px;
                    }
                    .chart-wrapper {
                        background-color: #f8f9fa;
                        border: 0.5px solid #e9ecef;
                        padding: 20px 0;
                        min-height: 265px;
                    }
                    .how-it-works {
                        text-align: center;
                    }
                    .step-title {
                        color: #4a4a4a;
                    }

                    .window-box {
                        margin-bottom: 1rem;
                    }

                    .window-box:last-of-type {
                        margin-bottom: 0;
                    }

                    .thumbnail {
                        padding: 0.25rem;
                        border-radius: 0;
                        max-width: 100%;
                        height: auto;
                    }

                    .window-links {
                        margin-top: 1rem;
                    }

                    .window-description > :global(p:last-of-type) {
                        margin-bottom: 0;
                    }

                    .make-sticky {
                        position: sticky;
                        top: 3.75rem;
                        background-color: white;
                        z-index: 2;
                    }

                    @media (min-width: ${theme.breakpoint.md}) {
                        .make-sticky {
                            top: 4.333333rem;
                        }
                    }
                    .online-learning {
                        background-color: #ebebeb;
                    }
                    .carousel {
                        width: 100%;
                        height: 300px;
                    }
                    #content > section:first-child > div:first-child {
                        margin-top: 0 !important;
                        padding-top: 117px;
                    }
                `}
            </style>
            <section>
                <div
                    className={"d-flex align-items-center"}
                    style={{
                        backgroundImage:
                            "url(/static/cover-images/workshop-people.jpg)",
                        backgroundSize: "cover",
                        height:
                            currentRouteKey === "capacity-building"
                                ? "500px"
                                : "417px",
                        width: "100%",
                        backgroundPositionY: "33%"
                    }}
                >
                    {currentRouteKey === "capacity-building" ? (
                        <div className={"container justify-content-center"}>
                            <div className="">
                                <h2 className="title font-weight-bold text-left tw-text-3xl">
                                    Capacity Building
                                </h2>
                                <h3
                                    className={
                                        "title h5 mb-0 mt-0 w-100 text-left"
                                    }
                                >
                                    Convergence hosts a range of blended finance
                                    capacity building programs for
                                    practitioners. Options are available across
                                    all audience levels and are offered both
                                    in-person and online.
                                </h3>
                            </div>
                        </div>
                    ) : null}
                    {currentRouteKey === "event-calendar-library" ? (
                        <div className={"container justify-content-center"}>
                            <div className="">
                                <h2 className="title font-weight-bold text-left tw-text-3xl">
                                    Past Events
                                </h2>
                            </div>
                        </div>
                    ) : null}
                    {currentRouteKey === "event-calendar" ? (
                        <div className={"container justify-content-center"}>
                            <div className="">
                                <h2 className="title font-weight-bold text-left tw-text-3xl">
                                    Event Calendar
                                </h2>
                            </div>
                        </div>
                    ) : null}
                </div>
            </section>
            <section>
                <div className="container is-content-container">
                    <div className="row">
                        {currentRouteKey !== "capacity-building" ? (
                            <div className="col-md-12 order-md-last">
                                <Nav tabs>
                                    <NavItem>
                                        {tabLink(
                                            {
                                                id: "event-calendar"
                                            },
                                            "event-calendar",
                                            "Calendar",
                                            currentRouteKey
                                        )}
                                    </NavItem>
                                    <NavItem>
                                        {tabLink(
                                            {
                                                id: "event-calendar-library"
                                            },
                                            "event-calendar-library",
                                            "Past Events",
                                            currentRouteKey
                                        )}
                                    </NavItem>
                                </Nav>
                            </div>
                        ) : null}
                    </div>
                    {currentRouteKey === "capacity-building" && (
                        <div className="row pt-3">
                            <div className="col-md-2 pr-0 order-md-1 mt-3 make-sticky">
                                <Sticky
                                    containerStyle={{
                                        top: "4.75rem",
                                        display: "flex",
                                        // flexDirection: "column",
                                        width: "100%",
                                        overflow: "auto"
                                    }}
                                >
                                    {() => (
                                        <>
                                            <SideMenu
                                                currentRouteKey={
                                                    currentRouteKey
                                                }
                                                filteredNavLinks={
                                                    filteredNavLinks
                                                }
                                                portfolioNavLinks={
                                                    portfolioNavLinks
                                                }
                                                ScrollingNavItem={
                                                    ScrollingNavItem
                                                }
                                            />
                                        </>
                                    )}
                                </Sticky>
                            </div>
                            <div className="col-md-10 order-md-2 mt-4">
                                <>
                                    <CapacitySection
                                        heading="Learning Programs"
                                        id="learning-programs"
                                        description={
                                            <>
                                                Convergence regularly organizes
                                                virtual blended finance learning
                                                programs designed to support
                                                participants in getting up to
                                                speed on specific themes and
                                                answer common questions. These
                                                programs focus on the state of
                                                the field and feature industry
                                                experts alongside practical
                                                examples to build capacity and
                                                demonstrate key concepts.
                                                <br />
                                                <br />
                                                <strong>
                                                    Target audience:
                                                </strong>{" "}
                                                Introductory/Intermediate Level.
                                                <br />
                                                <strong>Cost:</strong>{" "}
                                                Complimentary for Members, $75
                                                USD for Non-Members.
                                            </>
                                        }
                                        events={learningPrograms}
                                        colour={BRAND_COLOURS.dark}
                                        bgColour={BRAND_COLOURS.light}
                                        tag={"categoryLearningPrograms"}
                                    />
                                    <CapacitySection
                                        heading="Inside The Deal"
                                        id="inside-the-deal"
                                        description={
                                            <>
                                                Convergence organizes quarterly
                                                "Inside the Deal" webinars that
                                                dive deep into the profile of a
                                                closed blended finance
                                                transaction. These sessions
                                                include participation from
                                                various stakeholders that
                                                sponsored and/or invested in the
                                                featured transaction and focus
                                                on sharing lessons learned from
                                                the deal structuring process.
                                                <br />
                                                <br />
                                                <strong>
                                                    Target audience:
                                                </strong>{" "}
                                                Intermediate to Advanced.{" "}
                                                <i>
                                                    Please note these webinars
                                                    are only available to
                                                    Convergence Members.
                                                </i>
                                                <br />
                                                <strong>Cost:</strong>{" "}
                                                Complimentary for Members.
                                            </>
                                        }
                                        events={insideTheDeal}
                                        reverse
                                        bgColour={BRAND_COLOURS.white}
                                        tag="categoryInsideTheDeal"
                                    />
                                    <CapacitySection
                                        heading="Deal Connect"
                                        id="deal-connect"
                                        description={
                                            <>
                                                Convergence hosts quarterly
                                                "Deal Connect" webinars where
                                                Convergence members actively
                                                fundraising for a blended
                                                transaction can showcase their
                                                strategy to investor members.
                                                <br />
                                                <br />
                                                <strong>
                                                    Target audience:
                                                </strong>{" "}
                                                Intermediate to Advanced.{" "}
                                                <i>
                                                    Please note these webinars
                                                    are only available to
                                                    Convergence Members.
                                                </i>
                                                <br />
                                                <strong>Cost:</strong>{" "}
                                                Complimentary for Members.
                                            </>
                                        }
                                        events={dealConnect}
                                        tag="categoryDealConnect"
                                    />
                                    <CapacitySection
                                        heading="Thematic Webinars"
                                        id="thematic-webinars"
                                        description={
                                            <>
                                                Convergence organizes a variety
                                                of online events and
                                                info-sessions that cater to both
                                                members and non-members
                                                organized along specific themes.
                                                These events can be either
                                                public or closed-door, depending
                                                on the intended audience.
                                            </>
                                        }
                                        events={thematicWebinars}
                                        reverse
                                        bgColour={BRAND_COLOURS.white}
                                        tag="categoryThematicWebinars"
                                    />
                                    <section>
                                        <div className="fluid-container online-learning p-4 mb-5">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h3
                                                        className="mb-4 tw-text-2xl"
                                                        id="online-learning"
                                                    >
                                                        Online Learning
                                                    </h3>
                                                    <p>
                                                        Take our self-paced
                                                        e-learning course
                                                    </p>
                                                    <img
                                                        src={
                                                            "/static/cover-images/online-learning.jpg"
                                                        }
                                                        alt={
                                                            "Demystifying Blended Finance"
                                                        }
                                                        className={"lead-img"}
                                                        style={{
                                                            objectFit:
                                                                "contain",
                                                            maxHeight: "600px",
                                                            width: "100%",
                                                            backgroundPositionY:
                                                                "center"
                                                        }}
                                                    />
                                                    <h4 className="mb-4 text-center tw-py-4 tw-text-2xl">
                                                        Demystifying Blended
                                                        Finance
                                                    </h4>
                                                    {courses &&
                                                        courses.length > 0 && (
                                                            <div className="d-flex flex-wrap">
                                                                {courses
                                                                    .filter(
                                                                        (
                                                                            course
                                                                        ) => {
                                                                            if (
                                                                                !user
                                                                            )
                                                                                return true;
                                                                            return (
                                                                                course.access ===
                                                                                "Members"
                                                                            );
                                                                        }
                                                                    )
                                                                    .map(
                                                                        (
                                                                            course
                                                                        ) => (
                                                                            <CourseArticleBox
                                                                                article={
                                                                                    course
                                                                                }
                                                                                user={
                                                                                    user
                                                                                }
                                                                                compact
                                                                            />
                                                                        )
                                                                    )}
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h4
                                                        className="mt-5 mb-4 tw-text-2xl"
                                                        id="convergence-answers"
                                                    >
                                                        Convergence Answers
                                                    </h4>
                                                    <div className="d-flex flex-wrap">
                                                        {videos.map((event) => (
                                                            <CapacityBuildingEventBox
                                                                key={event.id}
                                                                event={{
                                                                    ...event,
                                                                    name:
                                                                        event.title
                                                                }}
                                                                className="text-center"
                                                                video
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section className="">
                                        <div className="fluid-container pb-4 pl-3  mb-5">
                                            <div className="row mr-0">
                                                <div className={`col-md-6 p-4`}>
                                                    <h3
                                                        className="mb-4 tw-text-2xl"
                                                        id={"bespoke-workshops"}
                                                    >
                                                        Customized Workshops
                                                    </h3>
                                                    <p>
                                                        Convergence offers
                                                        workshops and customized
                                                        sessions for public and
                                                        private institutions
                                                        interested in learning
                                                        about and applying
                                                        blended finance in their
                                                        work. Workshops can be
                                                        held in-person or
                                                        virtually and are
                                                        tailored to the
                                                        organization's specific
                                                        needs, interests, and
                                                        budget. <br />
                                                        <br />
                                                        <strong>
                                                            Target audience:
                                                        </strong>{" "}
                                                        Beginner to Advanced.{" "}
                                                        <br />
                                                        <strong>
                                                            Cost:
                                                        </strong>{" "}
                                                        Customized workshops
                                                        vary in price and
                                                        typically start at
                                                        $5,000 USD. Contact us
                                                        to discuss your
                                                        institution's capacity
                                                        building needs.
                                                    </p>
                                                    <Link
                                                        // target={""}
                                                        routeKey={"contact"}
                                                        label={"Contact Us"}
                                                        size={"md"}
                                                        colour={"secondary"}
                                                        isButton
                                                    />
                                                </div>
                                                <div
                                                    className={`col-md-6 fluid-background p-4`}
                                                >
                                                    <div
                                                        className={
                                                            "row d-flex align-items-center"
                                                        }
                                                    >
                                                        <div className="d-flex flex-wrap justify-content-center  w-100">
                                                            {logos.map(
                                                                (logo) => (
                                                                    <img
                                                                        src={
                                                                            logo.url
                                                                        }
                                                                        alt={
                                                                            logo.alt
                                                                        }
                                                                        className={
                                                                            "lead-img"
                                                                        }
                                                                        style={{
                                                                            // backgroundSize:
                                                                            //     "cover",
                                                                            height:
                                                                                "auto",
                                                                            width:
                                                                                "auto",
                                                                            maxWidth:
                                                                                "300px",
                                                                            maxHeight:
                                                                                "100px",
                                                                            padding:
                                                                                "1rem"
                                                                            // backgroundPositionY:
                                                                            //     "center"
                                                                        }}
                                                                    />
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section className="">
                                        <div className="fluid-container pb-4 pl-3">
                                            <div
                                                className="row"
                                                id={"academic-partnerships"}
                                            >
                                                <div className={`col-md-6 p-4`}>
                                                    <img
                                                        src={
                                                            "/static/cover-images/dom-fou-YRMWVcdyhmI-unsplash.jpg"
                                                        }
                                                        alt={
                                                            "Demystifying Blended Finance"
                                                        }
                                                        className={"lead-img"}
                                                        style={{
                                                            backgroundSize:
                                                                "cover",
                                                            height: "400px",
                                                            width: "100%",
                                                            backgroundPositionY:
                                                                "center",
                                                            objectFit: "cover"
                                                        }}
                                                    />
                                                </div>
                                                <div className={`col-md-6 p-4`}>
                                                    <h3 className="mb-4 tw-text-2xl">
                                                        Academic Partnerships
                                                    </h3>
                                                    <p>
                                                        Convergence partners
                                                        with select academic
                                                        institutions to create
                                                        learning programs such
                                                        as the{" "}
                                                        <a
                                                            href="https://ibf-uzh.ch/education/"
                                                            target="_blank"
                                                        >
                                                            Annual University of
                                                            Zurich
                                                        </a>
                                                        , and{" "}
                                                        <a
                                                            href="https://educacioncontinua.uniandes.edu.co/es/programas/blended-finance-finanzas-mixtas-e-inversion-de-impacto-enfoque-en-latinoamerica-y-colombia"
                                                            target="_blank"
                                                        >
                                                            Universidad de Los
                                                            Andes
                                                        </a>{" "}
                                                        programmes.
                                                    </p>
                                                </div>
                                                <div
                                                    className={`col-md-12 p-4`}
                                                >
                                                    <h3
                                                        className="mt-4 mb-4 tw-text-2xl"
                                                        id={"networking-events"}
                                                    >
                                                        Networking Events
                                                    </h3>
                                                    <p>
                                                        Convergence hosts
                                                        in-person networking
                                                        events in various
                                                        markets for our members,
                                                        partners, and grantees.
                                                        These events are
                                                        opportunities to learn
                                                        from peers, exchange
                                                        ideas, and make new
                                                        connections in the
                                                        blended finance
                                                        ecosystem. Recently
                                                        we've gathered in
                                                        Nairobi, New York,
                                                        Singapore, and
                                                        Washington, DC.{" "}
                                                        <Link
                                                            routeKey={"contact"}
                                                            label={"Contact Us"}
                                                        />{" "}
                                                        if you are interested in
                                                        co-hosting an event to
                                                        bring together blended
                                                        finance practitioners in
                                                        a specific market or to
                                                        convene stakeholders on
                                                        a particular topic or
                                                        theme of interest.
                                                    </p>
                                                    <div
                                                        className="w-100 overflow-hidden"
                                                        style={{ height: 400 }}
                                                    >
                                                        <SocialCarousel />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </>
                            </div>
                        </div>
                    )}
                </div>
            </section>
            {currentRouteKey === "event-calendar-library" && (
                <ContentfulEventsListing currentRouteKey={currentRouteKey} />
            )}
            {currentRouteKey === "event-calendar" && (
                <ContentfulEventsCalendar currentRouteKey={currentRouteKey} />
            )}
        </React.Fragment>
    );
};

CapacityBuildingOverview.propTypes = {
    // grantArticles: PropTypes.array.isRequired
};

export default CapacityBuildingOverview;
