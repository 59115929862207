import React from "react";
import theme from "../../theme/3.0/theme";

export default function CourseRegisterLink({ link }) {
    return (
        <React.Fragment>
            <a href={link} className={"btn btn-md btn-success register-button"}>
                View details
            </a>
            <style jsx>{`
                .register-button {
                    // width: 100%;
                    // display: block;
                    border-left: 0;
                    margin-top: 1rem;
                }

                .register-button:disabled {
                    background-color: ${theme.colour.gray400};
                    color: ${theme.colour.white};
                }

                .register-button:hover {
                    background-color: ${theme.colour.primaryHover};
                }
            `}</style>
        </React.Fragment>
    );
}
