/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import React from "react";

/**
 * Scroll Spy
 */
type Props = {
    render;
    navLinks;
};
type State = {
    links;
};
class ScrollSpy extends React.Component<Props, State> {
    updateNavThrottled: any;
    cache: {};
    static propTypes: {
        navLinks: PropTypes.Validator<any[]>;
        render: PropTypes.Validator<(...args: any[]) => any>;
    };
    constructor(props) {
        super(props);
        this.state = {
            links: Array.from(new Set(this.props.navLinks))
        };
        this.updateNavThrottled = debounce(this.updateNav, 0, {
            leading: true,
            trailing: false
        });
        this.cache = {};
    }

    componentDidMount() {
        window.addEventListener("scroll", this.updateNavThrottled);
    }

    componentDidUpdate(prevProps) {
        if (this.props.navLinks !== prevProps.navLinks) {
            this.setState({ links: this.props.navLinks });
        }
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.updateNavThrottled);
    }

    updateNav = () => {
        const { links } = this.state;

        const totalLinks = links.length;

        for (let linkIndex in links) {
            if (Object.prototype.hasOwnProperty.call(links, linkIndex)) {
                let link = links[linkIndex];
                let element = document.getElementById(link.hash);

                let nextLinkIndex = parseInt(linkIndex) + 1;
                let nextLink = null;
                let nextElement = null;

                if (totalLinks >= nextLinkIndex + 1) {
                    nextLink = links[nextLinkIndex];
                    nextElement = document.getElementById(nextLink.hash);
                }

                if (element) {
                    //The Element.getBoundingClientRect() method returns the size of an element and its position relative to the viewport.
                    // TS CODE CHANGE: top is a number. no need to parseInt
                    let top = element.getBoundingClientRect().top;
                    let nextElementTop = 0;

                    // Get next element
                    if (nextElement) {
                        nextElementTop = parseInt(
                            nextElement.getBoundingClientRect().top
                        );
                    }

                    if (top <= 100 && (!nextElement || nextElementTop > 100)) {
                        this.setState(({ links }) => {
                            links = links.map((mappedLink) => {
                                mappedLink.active =
                                    mappedLink.hash === link.hash;
                                return mappedLink;
                            });
                            return {
                                links
                            };
                        });
                        break;
                    }
                }
            }
        }
    };

    render() {
        const { render: Component } = this.props;
        const { links } = this.state;

        if (links) {
            return links.map((link, index) => <Component {...link} />);
        } else {
            return <React.Fragment />;
        }
    }
}

ScrollSpy.propTypes = {
    navLinks: PropTypes.array.isRequired,
    render: PropTypes.func.isRequired
};

export default ScrollSpy;
