import React, { useEffect, useRef } from "react";
import { NavItem, NavLink } from "reactstrap";

export const ScrollingNavItem = ({ hash, label, active }) => {
    const ref = useRef();
    useEffect(() => {
        if (active) {
            // @ts-ignore
            ref.current?.scrollIntoView({
                behaviour: "smooth",
                block: "nearest"
            });
        }
    }, [active]);
    return (
        <>
            <style jsx>
                {`
                    .nav-link:first-of-type {
                        padding-left: 0;
                    }
                    .nav-link {
                        color: #555;
                        font-size: 0.833333rem;
                        padding-bottom: 2rem;
                    }
                    .nav-link.active {
                        color: #29a4da;
                        font-weight: bold;
                    }
                `}
            </style>
            <div ref={ref} className={"scrollNavItem"}>
                <NavItem key={hash}>
                    <NavLink
                        href={"#" + hash}
                        active={active}
                        style={{
                            color: active ? "#29a4da" : "#555",
                            fontWeight: active ? "bold" : "normal",
                            fontSize: "0.833333rem",
                            paddingBottom: "1rem"
                        }}
                    >
                        {label}
                    </NavLink>
                </NavItem>
            </div>
        </>
    );
};
