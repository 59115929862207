/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import React from "react";
import {
    DEAL_TYPES_FILTER,
    IMPACT_THEMES_FILTER,
    PAGE_EVENTS,
    REGIONS_FILTER,
    SECTORS_AND_SUB_SECTORS_FILTER,
    KNOWLEDGE_TYPES_FILTER,
    BRAND_COLOURS,
    LEARNING_TYPES_FILTER,
    BLENDING_ARCHETYPE_FILTER
} from "../../../constants";
import {
    b64DecodeUnicode,
    b64EncodeUnicode,
    toContentfulFilters
} from "../../../utils/CommonUtils";
import BaseContentfulPagedListing from "../../general/BaseContentfulPagedListing";
import PagedContentfulFilterBar from "../../general/PagedContentfulFilterBar";
import Loader from "../../layout/Loader";
import CapacityBuildingEventBox from "./CapacityBuildingEventBox";
import ToggleSwitch from "../../theme/3.0/Form/Button/ToogleSwitch";
import { User } from "../../../types/mainTypes";
import { Nav, NavItem, NavLink } from "reactstrap";
import BaseLink from "../../BaseLink";

const tabLink = (article, tabKey, tabLabel, currentPage) => {
    const active = tabKey === currentPage;
    return (
        <BaseLink routeKey={tabKey}>
            {({ url }) => (
                <NavLink
                    href={url}
                    active={active}
                    style={
                        active && tabKey === "design-funding"
                            ? {
                                  backgroundColor: "#f8f9fa",
                                  borderBottomColor: "#f8f9fa",
                                  borderLeftColor: "#f8f9fa"
                                  //   borderTopColor: "#f8f9fa"
                              }
                            : {}
                    }
                >
                    {tabLabel}
                </NavLink>
            )}
        </BaseLink>
    );
};

type Props = {
    currentRouteKey: string;
    // TS no user or enableFilters here
};
class ContentfulEventsListing extends BaseContentfulPagedListing<Props> {
    initialFilters: any;
    constructor(props) {
        super(props);

        this.contentType = PAGE_EVENTS;

        const queryData = this.getQueryData();
        this.initialFilters = {
            access: ["Public", "Members"],
            "fields.date[lt]": [new Date().toISOString()],
            ...(typeof queryData.filters === "object" ? queryData.filters : {}),
            ...(typeof queryData.tag === "string"
                ? { "metadata.tags.sys.id[in]": queryData.tag }
                : {})
        };

        this.availableFilters = {
            instrumentFocus: DEAL_TYPES_FILTER,
            blendingInstrument: BLENDING_ARCHETYPE_FILTER,
            sectors_and_sub_sectors: SECTORS_AND_SUB_SECTORS_FILTER,
            regions: REGIONS_FILTER,
            impact_themes: IMPACT_THEMES_FILTER
            // type: LEARNING_TYPES_FILTER
        };

        this.defaultFilter = "instrumentFocus";

        this.state = {
            loadingMore: false,
            disableLoadMore: false,
            loading: true,
            skip: 0,
            limit: 12,
            contentful: {
                orderDirection: "DESC",
                orderByField: "fields.date",
                query: queryData.keyword ? queryData.keyword : "",
                filters: this.initialFilters
            },
            articles: []
        };
    }

    onTagOnlyChange = (event, name) => {
        const target = event.target;
        const value =
            target.type === "checkbox" ? target.checked : target.value;

        this.updateState((prevState) => {
            const filters = prevState.contentful.filters;

            if (filters["fields.tags[in]"]?.length > 0) {
                filters["fields.tags[in]"] = filters["fields.tags[in]"].split(
                    ","
                );
            }

            filters["fields.tags[in]"] =
                value === true
                    ? [...(filters["fields.tags[in]"] ?? []), name]
                    : filters["fields.tags[in]"]?.filter((t) => t !== name) ??
                      [];

            if (filters["fields.tags[in]"].length > 0) {
                filters["fields.tags[in]"] = filters["fields.tags[in]"].join(
                    ","
                );
            }
            const newState = {
                contentful: {
                    ...prevState.contentful,
                    filters
                }
            };

            return newState;
        });
    };

    render() {
        const {
            loading,
            loadingMore,
            disableLoadMore,
            articles,
            contentful
        } = this.state;
        const { currentRouteKey } = this.props;
        return (
            <>
                <style jsx>
                    {`
                        .library-bar {
                            background-color: #d3d3d3;
                            padding-top: 2rem;
                        }
                        .library-bar .filters-bar {
                            background-color: #d3d3d3;
                        }
                    `}
                </style>
                <div className={"pt-4 pb-4"}>
                    <div className={"container"}>
                        <div className={"row mb-4"}>
                            <div
                                className={
                                    "col-md-12 d-flex justify-content-start"
                                }
                            >
                                {!loading && (
                                    <PagedContentfulFilterBar
                                        contentful={contentful}
                                        // enableSortButton
                                        availableFilters={this.availableFilters}
                                        defaultFilter={this.defaultFilter}
                                        updateState={this.updateState}
                                        toContentfulFilters={
                                            toContentfulFilters
                                        }
                                        initialFilters={this.initialFilters}
                                        // filterTags={[
                                        //     "categoryLearningPrograms",
                                        //     "categoryInsideTheDeal"
                                        // ]}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={"container"}>
                        <div className={"row"}>
                            {loading && (
                                <div className={"col-12"}>
                                    <Loader />
                                </div>
                            )}
                            {!loading && (
                                <React.Fragment>
                                    {articles &&
                                        articles.length > 0 &&
                                        articles.map((article) => {
                                            return (
                                                <CapacityBuildingEventBox
                                                    key={article.id}
                                                    event={article}
                                                    // showIntro
                                                />
                                            );
                                        })}
                                    {articles && articles.length <= 0 && (
                                        <div className={"col-12"}>
                                            <div className={"alert alert-info"}>
                                                Sorry, no resources were found.
                                                Try changing your search or
                                                filter criteria.
                                            </div>
                                        </div>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                        {!disableLoadMore && (
                            <div className={"row"}>
                                <div className={"col-12 text-center"}>
                                    <button
                                        className={"btn btn-primary"}
                                        onClick={this.loadMore}
                                        disabled={loading || loadingMore}
                                    >
                                        {loadingMore
                                            ? "Loading..."
                                            : "Load More"}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

export default ContentfulEventsListing;
