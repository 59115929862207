/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, UncontrolledTooltip } from "reactstrap";
import ArticleBox from "../../layout/ArticleBox/ArticleBox";
import faLock from "@fortawesome/fontawesome-free-solid/faLock";
import CourseRegisterLink from "./CourseRegisterLink";
import Link from "../../theme/3.0/Link";

class CourseArticleBox extends React.Component {
    render() {
        const {
            article: course,
            user,
            compact = false,
            currentPath = "/capacity-building#online-learning"
        } = this.props;
        const memberCourse = course?.access === "Members";
        const courseLink = {
            routeKey: "course-view",
            query: { id: course.id }
        };
        const redirect = {
            url: "/login?redirect=" + currentPath
        };
        const link = user
            ? courseLink
            : memberCourse
            ? "/login?redirect=" + currentPath
            : courseLink;
        // const link = `https://convergence-learning.pages.dev/${course.iFrameSource}`;

        return (
            <ArticleBox
                className={"cardboard course"}
                middleContent={
                    <div style={{ position: "relative", height: "100%" }}>
                        <div
                            style={{
                                right: "-10px",
                                top: "-25px",
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                height: "100%"
                            }}
                        >
                            <p>{course?.name}</p>

                            {user ? (
                                <Link
                                    routeKey={"course-view"}
                                    params={{ id: course.id }}
                                    label={"View Details"}
                                    isButton
                                    size={"sm"}
                                    className="btn-success"
                                    target={"_blank"}
                                />
                            ) : !memberCourse ? (
                                <Link
                                    routeKey={"course-view"}
                                    params={{ id: course.id }}
                                    // url={`https://convergence-learning.pages.dev/${course.iFrameSource}`}
                                    label={"View Details"}
                                    isButton
                                    size={"sm"}
                                    className="btn-success"
                                    target={"_blank"}
                                />
                            ) : (
                                <>
                                    <Button
                                        href={"/login?redirect=" + currentPath}
                                        className={"btn btn-sm"}
                                        title={"Members only"}
                                        id={"start-members-only-button"}
                                    >
                                        <FontAwesomeIcon icon={faLock} />{" "}
                                        Members Only
                                    </Button>
                                </>
                            )}
                        </div>
                        {/* {course?.type} */}
                    </div>
                }
                // topContent={course?.name}
                image={course?.image}
                imageTitle={course?.name}
                imagePosition={compact ? "none" : "bottom-cover"}
                link={link}
            />
        );
    }
}
export default CourseArticleBox;
